import React, {useEffect} from 'react';
const pdf = "/ukazka-jidelnicku.pdf";

const UkazkaJidelnickuPage = () => {
    useEffect(() => {
        window.open(pdf);
    });

    return <>Ukazka Jidelnicku</>
};

export default UkazkaJidelnickuPage